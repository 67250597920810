export default {
    "data": [{
            "title": "【宠物筹特点介绍】",
            "level": 1,
            "content": "宠物筹致力于帮助无力承担医疗费用的大病患者筹集医疗费用，为社会爱心人士与大病患者建立爱心捐助桥梁。只要您是无力承担医疗费用的大病患者，都可在宠物筹发起筹款，填写相关信息后，即可将筹款页面转发出去，邀请亲朋好友帮助你或帮你转发。您在筹款的过程中，若急需提现，可不中断筹款，直接快速提现。若您想要结束筹款，可随时结束，结束后也可随时开启。",
        },
        {
            "title": "【发起筹款】相关问题",
            "list": [{
                    "title": "2.1、怎样发起筹款？",
                    "content": "您好，发起筹款可直接点击宠物筹公众号底部菜单栏【发起筹款】，根据提示填写筹款金额、筹款标题、详细情况、上传照片完成后点击“提交”。"
                },
                {
                    "title": "2.2、什么情况下可以筹款？",
                    "content": "您好，无力承担医疗费用的大病患者可以在宠物筹发起筹款。"
                }
            ]
        },
        {
            "title": "【项目管理】相关问题",
            "list": [{
                    "title": "3.1、我发起的项目验证审核是否通过？",
                    "content": "您好，筹款资料认证时间一般是在1-2个工作日之内完成。"
                },
                {
                    "title": "3.2、提现到“直系亲属”收款人的银行账户，需要什么资料？",
                    "content": "您好， 提现到患者直系亲属的银行账户，应上传收款人手持证件照片（照片需清晰可见证件号码）以及收款人和患者的户口本照片 （带有双方户号的：患者个人页、收款人个人页）。 若患者与收款人不在同一个户口本，也可上传相关部门带公章的关系证明。"
                },
                {
                    "title": "3.3、什么人属于直系亲属？",
                    "content": "您好，配偶、父母、子女、兄弟姐妹、祖父母外祖父母、孙子女外孙子女等直系亲属，可以作为收款人收款。"
                },
                {
                    "title": "3.4、提现到“医院”，需要什么资料？",
                    "content": "您好，提现到医院的银行账户，需要填写以下信息：<br/>1.住院信息（患者住院号、科室、床号）<br/>2.医院对公账户信息｛开户名称、银行卡号、开户行支行信息（需要填写省市并精确到具体支行）｝。"
                }
            ]
        },
        {
            "title": "【提现】相关问题",
            "list": [{
                    "title": "4.1、怎么提现？",
                    "content": "您好，您进入宠物筹的微信公众号后，点击右下角我的筹款-我的筹款，进入您的项目页面，点击底部按钮“快速提现”。根据提示填写提现申请， 提现申请通过审核后，您筹得的款项会在1-2个工作日进行打款。"
                },
                {
                    "title": "4.2、没达到目标金额能提现吗？",
                    "content": "大病救助的项目无论是否能达成目标金额，只要提现审核通过，就可以申请提现。"
                },
                {
                    "title": "4.3、申请提现后还能不能继续筹款？",
                    "content": "您好，申请提现后，仍可继续筹款。"
                },
                {
                    "title": "4.4、提现到账时间以及提现进度查询？",
                    "content": "提现申请通过审核后，您筹得的款项会在1-2个工作日进行打款。"
                }
            ]
        },
        {
            "title": "【支持和转发】相关问题",
            "list": [{
                    "title": "5.1、怎么给患者捐款？",
                    "content": "您好，您打开要支持的项目链接，点击“立即支付”输入支持金额进行支付即可，项目发起人不能支持自己发起的项目 。"
                },
                {
                    "title": "5.2、如何转发项目链接？",
                    "content": "您好，您可以通过微信（微信好友、微信群、朋友圈），QQ（QQ好友、QQ空间、QQ群）， 贴吧等途径进行转发。同时您可以发动您身边的亲朋好友或同事一起为您转发，转发的越多，帮助的人才会越来越多。或者联系您当地新闻媒体的微信公众号，请他们帮您转发。"
                },
            ]
        },
    ]
}