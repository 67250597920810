<template>
    <div class="help-center" v-if="listData.length">
        <div class="label_title">帮助中心</div>

        <div class="group-item"
            v-for="(item, pIndex) in listData"
            :key="pIndex"
        >
            <div class="main-title border-bottom arrow-icon"
                :class="{'no-border arrow-up': item.fold}"
                @click="onMianGroup(pIndex)">{{item.title}}
            </div>

            <div class="list-content" v-if="item.fold">
                <template v-if="item.level == 1">
                    <div class="content" v-html="item.content"></div>
                </template>
                <div v-else
                    v-for="(cItem, cIndex) in item.list"
                    :key="cIndex"
                    @click="onChildGroup(pIndex, cIndex)"
                    class="list-item"
                >
                    <div class="sub-title border-bottom"
                        :class="{ 'no-border': cItem.fold }">{{cItem.title}}
                    </div>
                    <template v-if="cItem.fold" >
                        <div class="content" v-html="cItem.content"></div>
                    </template>
                </div>
            </div>
        </div>

        <!--
        <div class="group-item" >
            <div class="main-title" @click="onMianGroup">【项目管理】相关问题</div>
            <div class="list-content">
                <div class="list-item">
                    <div class="sub-title border-bottom">2.1、项目验证资料提交到哪里？</div>
                    <div class="desc" v-show="false">
                        您好，发起筹款可直接点击【发起筹款】，根据提示填写筹款金额、筹款标题、详细情况、上传照片完成后点击“提交”。 如果您有任何问题，可再次【联系客服】。
                    </div>
                </div>
                <div class="list-item">
                    <div class="sub-title border-bottom">2.2、发起大病救助筹款，需要如何提供资料？</div>
                    <div class="desc" v-show="false">
                        您好，发起筹款可直接点击【发起筹款】，根据提示填写筹款金额、筹款标题、详细情况、上传照片完成后点击“提交”。 如果您有任何问题，可再次【联系客服】。
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import list from './data.js';

export default {
    name: 'helpcenter',
    data () {
        return {
            list,
            listData: list.data,
            currtIndex: -1,
            parentIndex: 0,
        }
    },
    created() {},
    methods: {
        onMianGroup(index) {
            const currentObj = this.listData[index];
            this.setFold(currentObj)
        },

        onChildGroup(parentIndex, childIndex) {
            let listData = this.listData;
            listData.map((item, pIndex) => {
                if(item.level !== 1) {
                    let childList = item.list;
                    childList.map((item, index) => {
                        if(index === childIndex && parentIndex === pIndex) {
                            item['fold'] = !item['fold']
                        } else {
                            item['fold'] = false;
                        }
                        item;
                    });
                }
            })
            let newArr = [];
            this.listData = newArr.concat(listData);
        },

        setFold(currentObj) {
             let value = false;
            if (!currentObj.fold) {
                value = true;
            }
            this.$set(currentObj, 'fold', value);
        }
    }
}
</script>

<style lang='less' scoped>
.help-center {
    padding-bottom: 40px;
}
.label_title {
    font-size: 31px;
    font-weight: 600;
    color: #333333;
    line-height: 1;
    text-align: left;
    padding: 35px 16px;
}

.main-title {
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    line-height: 1;
    font-weight: 500;
    padding: 18px 16px 18px 8px;
    position: relative;
}

.arrow-icon::after {
    content: "";
    display: block;
    height: 9px;
    width: 9px;
    border-width: 1px 1px 0 0;
    border-color: #c8c8cd;
    border-style: solid;
    position: absolute;
    top: 50%;
    right: 16px;
    margin-top: -3px;
    transform: translateY(-50%) rotate(135deg);
}

.arrow-up::after {
    margin-top: 0;
    transform: translateY(-50%) rotate(-45deg);
}


.group-item {
    text-align: left;
    .sub-title {
        display: inline-block;
        margin-left: 5%;
        width: 90%;
        padding: 14px 0;
        font-size: 15px;
        background: #fff;
        color: #333;
    }

    .border-bottom {
        border-bottom: 1px solid #ececec;
    }
    .no-border {
        border-bottom: none;
    }

    .content {
        font-size: 14px;
        color: #333;
        padding: 14px 16px;
        line-height: 24px;
        background: #f6f6f6;
        color: #666;
        border-top: 1px solid #f7f7f7;
        border-bottom: 1px solid #f7f7f7;
    }
}

.list-item:last-child {
    .border-bottom {
        border-bottom: none;
    }
}

</style>
